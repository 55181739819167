// import { ref, computed } from 'vue';
import { api } from '@/services/Api';
import { Product, ProductRegion } from '@/models/Product';
import swal from 'sweetalert2';
import { generalStore } from '@/store';
import { Qualifier } from 'ag-grid-community';
import router from '@/router';
import Q from 'q';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { ChangeProductRegionsResponse, ChangeProductTagsResponse, DateRange, SaveProductResponse } from '@/models/Interfaces';
import {WizzardStep, OrderBlockTypes, OrderBlockOption, ProductAutoOrderNotificationType} from '@/models/Enums';
import { ApiResult } from '@/services/BaseApi';

export default function() {
    const { t } = useI18n();
    async function getProduct(id: string): Promise<Product | null> {
        if (id == '') return null;
        const product = generalStore.state.product;
        if (!product || product.id != id) {
            swal.showLoading();
            const response = await api.getProduct(id);
            if (response.data) {
                generalStore.commit('setProduct', response.data);
                swal.close();
            } else {
                swal.fire({
                    icon: 'error',
                    title: 'Such a product does not exist.',
                    text: response.errorMessage,
                    confirmButtonText: t('alert.backToCatalog')
                }).then(() => {
                    router.push({ name: 'catalog' });
                });
                return null;
            }
        }

        return generalStore.state.product;
    }

    function orderBlockTypesOpt() {
        return [
            { id: OrderBlockTypes.minutes, name: t('calendar.v3.modal.slot.minutes'), key: 'minutes' },
            { id: OrderBlockTypes.hours, name: t('calendar.v3.modal.slot.hours'), key: 'hours' },
            { id: OrderBlockTypes.days, name: t('calendar.v3.modal.slot.days'), key: 'days' }
        ];
    }

    function orderBlockOptionsOpt() {
        return [
            { id: OrderBlockOption.before, name: t('calendar.v3.modal.slot.before') },
            { id: OrderBlockOption.after, name: t('calendar.v3.modal.slot.after') },
            { id: OrderBlockOption.allow, name: t('calendar.v3.modal.slot.allow') }
        ];
    }

    function orderNotificationIntervalTypeOptionsOpt() {
        return [
            { id: ProductAutoOrderNotificationType.BeforeSlotTime, name: t('sms-email-management.product.BeforeSlotTime') },
            { id: ProductAutoOrderNotificationType.AfterSlotTime, name: t('sms-email-management.product.AfterSlotTime') }
        ];
    }

    function compareOrder(a: any, b: any) {
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
    }

    function getAverageDuration() {
        return [
            {
                id: 1,
                name: t('product.averageDuration.15min'),
                order: 1
            },
            {
                id: 2,
                name: t('product.averageDuration.30min'),
                order: 2
            },
            {
                id: 3,
                name: t('product.averageDuration.1hour'),
                order: 3
            },
            {
                id: 4,
                name: t('product.averageDuration.2hours'),
                order: 5
            },
            {
                id: 5,
                name: t('product.averageDuration.3hours'),
                order: 6
            },
            {
                id: 6,
                name: t('product.averageDuration.over3Hours'),
                order: 7
            },
            {
                id: 7,
                name: t('product.averageDuration.halfDay'),
                order: 8
            },
            {
                id: 8,
                name: t('product.averageDuration.wholeDay'),
                order: 9
            },
            {
                id: 9,
                name: t('product.averageDuration.1-5hour'),
                order: 4
            }
        ].sort(compareOrder);
    }
    async function saveProduct(product: Product): Promise<Product | undefined> {
        swal.showLoading();
        product.productPrices = [];
        const updateResponse = await api.saveProduct(product);

        await Q.delay(400);
        swal.close();
        if (updateResponse.errorMessage) {
            swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: updateResponse.errorMessage });
            return;
        }
        if (updateResponse.data) {
            if (updateResponse.data.warningCode == 588) {
                const swalResult = await swal.fire({
                    icon: 'info',
                    title: t('alert.updateProductSlotOrderBlockMin'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('editProductPage.cancelEdit.alertConfirm'),
                    cancelButtonText: t('editProductPage.cancelEdit.alertCancel')
                });
                if (swalResult.isConfirmed) {
                    api.updateProductSlotOrderBlockMin(updateResponse.data.product.id);
                }
            }

            generalStore.commit('setProduct', updateResponse.data.product);
            return updateResponse.data.product;
        }
    }

    async function saveProductWithResult(product: Product): Promise<ApiResult<SaveProductResponse>> {
        swal.showLoading();
        product.productPrices = [];
        const updateResponse = await api.saveProduct(product);
        await Q.delay(400);
        swal.close();
        if (updateResponse.data) {
            generalStore.commit('setProduct', updateResponse.data.product);
        }
        return updateResponse;
    }

    function formatToLocalTime(incomeTime: string): string {
        return moment(incomeTime, 'HH:mm').format('HH:mm:ss');
    }

    function dateRangeValidate(dateRange: DateRange) {
        const isNotValidDates = moment(dateRange.fromDate) > moment(dateRange.toDate);
        if (isNotValidDates) {
            dateRange.toDate = dateRange.fromDate;
        }
    }

    function getWizzardStep(stepName: string, subStep: string) {
        switch (stepName) {
            case 'createProductStepOne': {
                if (subStep == '1') return WizzardStep.Step1_1;
                if (subStep == '2') return WizzardStep.Step1_2;
                if (subStep == '3') return WizzardStep.Step1_3;
                if (subStep == '4') return WizzardStep.Step1_4;
                return WizzardStep.None;
            }
            case 'createProductStepTwo': {
                if (subStep == '1') return WizzardStep.Step2_1;
                return WizzardStep.None;
            }
            default:
                return WizzardStep.None;
        }
    }

    async function saveProductRegions(
        productId: string,
        regionsId: string[],
        fromWizzard = false
    ): Promise<ApiResult<ChangeProductRegionsResponse>> {
        swal.showLoading();
        const updateResponse = await api.saveRegions(productId, regionsId, fromWizzard);
        await Q.delay(400);
        swal.close();
        if (updateResponse.data) {
            generalStore.commit('setProductRegions', updateResponse.data.productRegions);
        }
        return updateResponse;
    }
    async function saveProductTags(
        productId: string,
        tagsId: string[],
        parentTagId: string,
        fromWizzard: boolean
    ): Promise<ApiResult<ChangeProductTagsResponse>> {
        swal.showLoading();
        const updateResponse = await api.saveTags(productId, tagsId, parentTagId, fromWizzard);
        await Q.delay(400);
        swal.close();
        if (updateResponse.data) {
            generalStore.commit('setProductTags', updateResponse.data);
        }
        return updateResponse;
    }
    return {
        saveProduct,
        saveProductWithResult,
        getProduct,
        formatToLocalTime,
        dateRangeValidate,
        getWizzardStep,
        saveProductRegions,
        saveProductTags,
        getAverageDuration,
        orderBlockTypesOpt,
        orderBlockOptionsOpt,
        orderNotificationIntervalTypeOptionsOpt
    };
}
