
import { defineComponent, PropType, ref, reactive } from 'vue';
import { SlotsWithNoEmployeeFilter } from '@/models/Employee';

import Multiselect from '@vueform/multiselect';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import Calendar from 'primevue/calendar';
import { DateRange } from '@/models/Interfaces';
import useProduct from '@/modules/useProduct';
import { SlotsWithNoEmployeeFilterFromModal, IdName } from '@/models/Interfaces';

export default defineComponent({
    components: { OverlayModal, Calendar, Multiselect },
    name: 'SlotsWithNoEmployeeFilterModal',
    emits: ['applyFilter'],
    props: {
        products: {
            type: Array as PropType<IdName[]>,
            default: () => []
        }
    },
    async setup(props, { emit }: any) {
        const isOpen = ref(false);
        const filter = ref<SlotsWithNoEmployeeFilter>(new SlotsWithNoEmployeeFilter());
        const filterDateRange = reactive(new DateRange());
        const { dateRangeValidate } = useProduct();
        function save() {
            const req: SlotsWithNoEmployeeFilterFromModal = {
                dataRange: filterDateRange,
                filter: filter.value
            };
            emit('applyFilter', req);
            isOpen.value = false;
        }
        function back() {
            isOpen.value = false;
        }
        const dateChanged = (dateRange: DateRange) => {
            if (!dateRange.fromDate || !dateRange.toDate) return;
            dateRangeValidate(dateRange);
        };
        function open(propFilter: SlotsWithNoEmployeeFilter) {
            filter.value = propFilter;
            isOpen.value = true;
        }

        return {
            isOpen,
            filter,
            filterDateRange,
            open,
            back,
            save,
            dateChanged
        };
    }
});
