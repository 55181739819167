
import { defineComponent, ref, onMounted, onUnmounted, inject, reactive, computed } from 'vue';
import { SlotsWithNoEmployeeFilter, SlotsWithNoEmployee, EmployeeFilter } from '@/models/Employee';
import WeekdaysSelect from '@/components/Calendar/WeekdaysSelect.vue';
import swal from 'sweetalert2';
import Q from 'q';
import { api } from '@/services/Api';
import { Product } from '@/models/Product';
import Multiselect from '@vueform/multiselect';
import { AwaitTaskResponse, DateRange, SlotsWithNoEmployeeFilterFromModal } from '@/models/Interfaces';
import Calendar from 'primevue/calendar';
import useProduct from '@/modules/useProduct';
import useOrder from '@/modules/useOrder';
import SlotsWithNoEmployeeFilterModal from '@/components/Modals/SlotsWithNoEmployeeFilterModal.vue';
import { useRouter } from 'vue-router';
import moment from 'moment';
import { ApiResult } from '@/services/BaseApi';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'SlotsWithNoEmployee',
    components: { WeekdaysSelect, Multiselect, Calendar, SlotsWithNoEmployeeFilterModal },
    async setup() {
        const { t } = useI18n();
        const router = useRouter();
        const loaded = ref(false);
        const mobileShow = ref(false);
        const desktopShow = ref(false);
        const products = ref<Product[]>([]);
        const busy = ref(false);
        const modalSlotsWithNoEmployeeFilter = ref<InstanceType<typeof SlotsWithNoEmployeeFilterModal>>();
        const { dateRangeValidate } = useProduct();
        const { convertDate } = useOrder();
        const appElement = document.getElementById('app');
        if (appElement) {
            const offsetWidth = appElement.offsetWidth;
            if (offsetWidth > 991) {
                desktopShow.value = true;
            } else {
                mobileShow.value = true;
            }
        }
        const filterDateRange = reactive(new DateRange());
        const hasNext = ref(false);
        const slots = ref<SlotsWithNoEmployee[]>([]);
        const filter = ref<SlotsWithNoEmployeeFilter>(new SlotsWithNoEmployeeFilter());
        if (filter.value) {
            filter.value.pageIndex = 0;
        }
        const emitter: any = inject('emitter');
        const getSlotsWithNoEmployeeApi = async () => {
            swal.showLoading();
            if (filterDateRange.fromDate) {
                filter.value.startDate = convertDate(filterDateRange.fromDate);
            }
            if (filterDateRange.toDate) {
                filter.value.endDate = convertDate(filterDateRange.toDate);
            }
            busy.value = true;
            const filterData: SlotsWithNoEmployeeFilter = JSON.parse(JSON.stringify(filter.value));
            const apiResult = await api.getSlotsWithNoEmployee(filterData);

            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            hasNext.value = apiResult.data?.hasNext || false;

            if (apiResult.data && slots.value && slots.value.length) {
                slots.value = [...slots.value, ...apiResult.data.rows];
            } else {
                slots.value = apiResult.data?.rows || [];
            }

            filter.value.pageIndex++;
            busy.value = false;
            swal.close();
        };
        async function getSlotsWithNoEmployee() {
            slots.value = [];
            filter.value.pageIndex = 0;
            await getSlotsWithNoEmployeeApi();
        }
        function getArrowClass(sort: string) {
            if (sort == filter.value.orderBy) {
                if (filter.value.asc) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        }
        async function sotrBy(sotrBy: string) {
            if (filter.value.orderBy != sotrBy) {
                filter.value.asc = true;
            } else {
                filter.value.asc = !filter.value.asc;
            }
            filter.value.orderBy = sotrBy;
            await getSlotsWithNoEmployee();
        }

        async function showAll() {
            filter.value = new SlotsWithNoEmployeeFilter();
            filterDateRange.fromDate = null;
            filterDateRange.toDate = null;
            await getSlotsWithNoEmployee();
        }
        const hasFilter = computed<boolean>(() => {
            if (filter.value.productIds.length > 0) {
                return true;
            }
            if (filter.value.startDate) {
                return true;
            }
            if (filter.value.endDate) {
                return true;
            }
            return false;
        });

        async function applyFilter(req: SlotsWithNoEmployeeFilterFromModal) {
            filter.value = new SlotsWithNoEmployeeFilter();
            filter.value.startDate = req.filter.startDate;
            filter.value.endDate = req.filter.endDate;
            filter.value.productIds = req.filter.productIds;
            filterDateRange.fromDate = req.dataRange.fromDate;
            filterDateRange.toDate = req.dataRange.toDate;
            await getSlotsWithNoEmployee();
        }

        function ShowFilter() {
            const propFilter: SlotsWithNoEmployeeFilter = JSON.parse(JSON.stringify(filter.value));
            modalSlotsWithNoEmployeeFilter.value?.open(propFilter);
        }

        function PushToDetails(slot: SlotsWithNoEmployee) {
            const slotStartDate = moment(slot.startDate, 'DD/MM/YYYY');
            // const today = moment();
            // const startDateMoment = slotStartDate.isBefore(today) ? today : slotStartDate;
            // const startDate = startDateMoment.format('YYYY-MM-DD');
            router.push({
                name: 'CalendarV3',
                params: {
                    employeeSlotId: slot.id,
                    view: 'week',
                    date: slotStartDate.format('YYYY-MM-DD')
                }
            });
        }
        function back() {
            router.go(-1);
        }

        const dateChanged = (dateRange: DateRange) => {
            if (!dateRange.fromDate || !dateRange.toDate) return;
            dateRangeValidate(dateRange);
        };

        function ListenerGetSlotsWithNoEmployee() {
            if (hasNext.value && !busy.value) {
                getSlotsWithNoEmployeeApi();
            }
        }
        async function onMountedCall() {
            emitter.on('isBottom', ListenerGetSlotsWithNoEmployee);
            await getSlotsWithNoEmployeeApi();
            loaded.value = true;
        }

        async function onUnmountedCall() {
            emitter.off('isBottom', ListenerGetSlotsWithNoEmployee);
        }

        async function routeToProduct(productId: string) {
            router.push({ name: 'product', params: { id: productId } });
        }

        async function awaitTask(action: () => Promise<ApiResult<AwaitTaskResponse>>, onSuccess: (url: string) => void, waitTime = 3000) {
            swal.fire(t('swalAction.processing'));
            swal.showLoading();
            await Q.delay(waitTime);

            const resp = await action();
            if (resp.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    text: resp.errorMessage
                });
                return;
            }
            if (resp.data?.url) {
                onSuccess(resp.data.url);
                return;
            }

            if (waitTime > 0) {
                awaitTask(action, onSuccess, waitTime);
            }
        }

        async function exportEmployeeSlots() {
            swal.showLoading();
            if (filterDateRange.fromDate) {
                filter.value.startDate = convertDate(filterDateRange.fromDate);
            }
            if (filterDateRange.toDate) {
                filter.value.endDate = convertDate(filterDateRange.toDate);
            }
            const filterData: SlotsWithNoEmployeeFilter = JSON.parse(JSON.stringify(filter.value));
            const apiResult = await api.exportEmployeesSlots(filterData);
            await Q.delay(400);
            if (apiResult.data?.error || !apiResult.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.data?.error || 'No data'
                });
                return;
            }

            const taskId = apiResult.data.taskId;
            awaitTask(
                () => api.waitExportTask(taskId),
                url => {
                    swal.fire({
                        icon: 'success',
                        title: t('alert.exportReady.orders'),
                        html: `
                    <div class="text-center my-4">
                        <a href="${url}" target="_blank">
                            <button role="button" class="popup-bookit-button my-1 px-4">${t('report.download')}</button>
                        </a>
                    </div>`,
                        showConfirmButton: false,
                        showCloseButton: true
                    });
                }
            );
        }

        onMounted(onMountedCall);
        onUnmounted(onUnmountedCall);
        return {
            desktopShow,
            mobileShow,
            slots,
            filter,
            products,
            filterDateRange,
            hasFilter,
            modalSlotsWithNoEmployeeFilter,
            loaded,
            applyFilter,
            ShowFilter,
            sotrBy,
            getArrowClass,
            PushToDetails,
            getSlotsWithNoEmployee,
            dateChanged,
            showAll,
            back,
            routeToProduct,
            exportEmployeeSlots
        };
    }
});
